import { Injectable } from '@angular/core';
import {Userfarmer} from './userfarmer.model';
import {AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import 'firebase/storage';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';


@Injectable()
export class UserfarmerService {
	userfarmerList : AngularFireList<any>
	selectedUser : Userfarmer = new Userfarmer();

  constructor(private firebase :AngularFireDatabase) { }

  getData(){
  	this.userfarmerList = this.firebase.list('userfarmer');
  	return this.userfarmerList;
  }

insertUser(userfarmer : Userfarmer)
{
	this.userfarmerList.push({
		name: userfarmer.name,
		village: userfarmer.village,
		address: userfarmer.address,
		mobile: userfarmer.mobile
	});
}

updateUser(userfarmer : Userfarmer)
{
	this.userfarmerList.update(userfarmer.$key,
		{
		name: userfarmer.name,
		village: userfarmer.village,
		address: userfarmer.address,
		mobile: userfarmer.mobile
	});
}

deleteUser($key : string){
	this.userfarmerList.remove($key);
}

}