import { Component, OnInit } from '@angular/core';
import {UserfarmerService} from './shared/userfarmer.service';


@Component({
  selector: 'app-usersfarmer',
  templateUrl: './usersfarmer.component.html',
  styleUrls: ['./usersfarmer.component.sass'],
  providers: [UserfarmerService]
})
export class UsersfarmerComponent implements OnInit {

  constructor(private userfarmerService : UserfarmerService) { }

  ngOnInit() {
  }

}
