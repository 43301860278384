import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {AboutComponent} from '../about/about.component';
import {AuthenticationGuard} from '../services/authentication.guard';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import "rxjs/add/observable/of";
import "rxjs/add/observable/from";
import "rxjs/add/observable/throw";

// const ROUTES: Routes  = [

//  // {path: '', redirectTo: '/app-farmer-userprofile', pathMatch: 'full', canActivate: [AuthenticationGuard]}, 
//  {path: 'app-farmer-userprofile', component: UserProfileComponent, canActivate: [AuthenticationGuard]},
//  {path: 'app-farmer-about', component: AboutComponent, pathMatch: 'full'},
//  {path: '**', redirectTo: 'app-farmer-page-not-found'},
// ];


const ROUTES: Routes = [
    {path: '', redirectTo: '/app-farmer-userprofile', pathMatch: 'full', canActivate: [AuthenticationGuard]},
    {path: 'app-farmer-userprofile', component: UserProfileComponent, canActivate: [AuthenticationGuard]},

];

@NgModule({
    imports: [
        RouterModule.forChild(ROUTES)
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthenticationGuard
    ]
})
export class UserRoutingModule {
}