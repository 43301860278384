import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SignupComponent} from './signup/signup.component';
import {LoginComponent} from './login/login.component';

export const ROUTES: Routes = [
    {path: 'app-farmer-signup', component: SignupComponent},
    {path: 'app-farmer-login', component: LoginComponent},
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)
  ],
  
  exports: [
  RouterModule
  ]
})
export class AuthenticationRoutingModule { }
