import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UserRoutingModule } from './user-routing.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import {EditDialogComponent} from '../edit-dialog/edit-dialog.component';
import { FarmerService } from '../services/farmer.service';
import {BrowserModule} from '@angular/platform-browser';


@NgModule({
  imports: [
    CommonModule,
    UserRoutingModule,
    FormsModule,
    UserRoutingModule
  ],
  declarations: [
  	UserProfileComponent,
  	EditDialogComponent
  ],
  providers: [
  FarmerService
  ]
})
export class UserModule { }
