import { BrowserModule } from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import {UserModule} from './user/user.module';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFireAuth} from 'angularfire2/auth';
import {CommonModule} from '@angular/common';
import {AppRouting} from './app.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RatingComponent } from './rating/rating.component';
import {AngularFireModule} from 'angularfire2';
import {AngularFireDatabaseModule} from 'angularfire2/database';
import {AngularFireDatabase} from 'angularfire2/database';
import {UsersfarmerComponent} from './usersfarmer/usersfarmer.component';
import {UserfarmerComponent} from './usersfarmer/userfarmer/userfarmer.component';
import {UserfarmerListComponent} from './usersfarmer/userfarmer-list/userfarmer-list.component';
import {UserService} from './services/user.service';
import {UserfarmerService} from './usersfarmer/shared/userfarmer.service';
import {FarmerService} from './services/farmer.service';
import {AuthenticationService} from './services/authentication.service';
import {AuthenticationModule} from './authentication/authentication.module';
import {WindowService} from './services/window.service';
import {Observable} from 'rxjs/Observable';
import * as firebase from 'firebase/app';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './notfound/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    RatingComponent,
    UsersfarmerComponent,
    UserfarmerComponent,
    UserfarmerListComponent,
    PageNotFoundComponent
    ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    AuthenticationModule,
    UserModule,
    CommonModule,
    FormsModule,
    AppRouting,
    NgbModule.forRoot()    
  ],
  providers: [
      AngularFireModule,
      AngularFireDatabaseModule,      
      AngularFireAuth,      
      AngularFireDatabase,
      UserService,
      UserfarmerService,
      AuthenticationService,
      WindowService,
      FarmerService
      ],
  bootstrap: [AppComponent]
})
export class AppModule { }
