import {FormsModule} from '@angular/forms';
import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import {AuthenticationService} from '../services/authentication.service';
import {UserService} from '../services/user.service';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import {ErrorAlertComponent} from '../shared/error-alert/error-alert.component';
import { PasswordEqualValidatorDirective } from '../utils/password-equal-validator.directive';



@NgModule({
  imports: [  
    FormsModule,
    CommonModule,
    AuthenticationRoutingModule
     
  ],
  declarations: [
    SignupComponent,
    LoginComponent,
    ErrorAlertComponent,
    PasswordEqualValidatorDirective
  ],
  providers: [
  	AuthenticationService,
    UserService
  ]

})
export class AuthenticationModule { }