// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCuEjj_8e9JcSMyEWOoPAZ83FppAGQBwNA",
    authDomain: "farmer-foodnet.firebaseapp.com",
    databaseURL: "https://farmer-foodnet.firebaseio.com",
    projectId: "farmer-foodnet",
    storageBucket: "farmer-foodnet.appspot.com",
    messagingSenderId: "556024327491",
    appId: "1:556024327491:web:3c2eab6616ab1b361213f6"
}

};
  
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.