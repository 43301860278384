import { Component, OnInit } from '@angular/core';
import {UserfarmerService} from '../shared/userfarmer.service';
import {Userfarmer} from '../shared/userfarmer.model';

@Component({
  selector: 'app-userfarmer-list',
  templateUrl: './userfarmer-list.component.html',
  styleUrls: ['./userfarmer-list.component.sass']
})
export class UserfarmerListComponent implements OnInit {	
	userfarmerList : Userfarmer[];
  constructor(private userfarmerService : UserfarmerService) { }

  ngOnInit() {
  	var x = this.userfarmerService.getData();
  	x.snapshotChanges().subscribe(item => {
  	this.userfarmerList = [];
  	item.forEach(element=>{
  		var y = element.payload.toJSON();
  		y["$key"] = element.key;
  		this.userfarmerList.push(y as Userfarmer);
  	})
  	});
  }

  onEdit(usrfarmer : Userfarmer){
    // this.userService.selectedUser = Object.assign({},usr);
     this.userfarmerService.selectedUser = usrfarmer;
  }

  onDelete(key : string){
    if (confirm('Are you sure to delete this record ?') == true){
    this.userfarmerService.deleteUser(key);
    }
  }
}
