import {Component} from '@angular/core';
import {AuthenticationService} from './services/authentication.service';
import { UserService} from './services/user.service';
import {User} from './services/user';
// import  {UserfarmerService} from './usersfarmer/shared//userfarmer/userfarmer.service';



@Component({
    selector: 'app-farmer',
    styleUrls: ['app.component.sass'],
    templateUrl: './app.component.html',
})
export class AppComponent {

	title = 'app';
	application ="Farmer";

    startAt: string;

    endAt: string;

    users: User[];
    // usersfarmer: User[];

    currentLoginUser: User;

    searchText: string;

    authenticationService: AuthenticationService;

    constructor(private authService: AuthenticationService,
                private userService: UserService) {
        this.authenticationService = authService;

    }


    message(value){
    console.log(value);
   }

   // ngOnInit(){
   //   this.message(this.title);
   // }

   ngOnInit() {
        this.userService.getSavedUser().subscribe((user) => {
            this.currentLoginUser = user;
        });
        this.searchText = '';
    }



  }