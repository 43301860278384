import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AboutComponent} from './about/about.component';
import {LoginComponent} from './authentication/login/login.component';
import {SignupComponent} from './authentication/signup/signup.component';
import {UserProfileComponent} from './user/user-profile/user-profile.component';
import { EditDialogComponent} from './edit-dialog/edit-dialog.component';




export const ROUTES: Routes = [
    {path: 'app-farmer-about', component: AboutComponent, pathMatch: 'full'},
    {path: '**', redirectTo: 'app-farmer-page-not-found'},
    {path: 'app-edit-dialog', component: EditDialogComponent, pathMatch: 'full'},
    {path: 'app-farmer-login', component: LoginComponent, pathMatch: 'full'},
    {path: 'app-farmer-signup', component: SignupComponent, pathMatch: 'full'},
    {path: 'app-farmer-userprofile', component: UserProfileComponent, pathMatch: 'full'},    
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            ROUTES
        )],
    exports: [
        RouterModule
    ]
})

export class AppRouting {
}