import { Component, OnInit } from '@angular/core';
import {UserfarmerService} from '../shared/userfarmer.service';
import {NgForm} from '@angular/forms';


@Component({
  selector: 'app-userfarmer',
  templateUrl: './userfarmer.component.html',
  styleUrls: ['./userfarmer.component.sass']
})
export class UserfarmerComponent implements OnInit {

  constructor(private userfarmerService : UserfarmerService) { }

  ngOnInit() {
  	this.userfarmerService.getData();
  	this.resetForm();
  }

  onSubmit(userForm : NgForm){
    if (userForm.value.$key == null)
  	this.userfarmerService.insertUser(userForm.value);
    else
    this.userfarmerService.updateUser(userForm.value);
  	this.resetForm(userForm);

  }

  resetForm(userForm? : NgForm){
  	if (userForm != null)
  	userForm.reset();
  	this.userfarmerService.selectedUser = {
  		$key : null,
  		name : '',
  		village : '',
  		address : '',
  		mobile : null
  	}
  }

}
